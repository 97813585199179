/* 
 * Copyright 2011-2024, The Trustees of Indiana University and Northwestern
 *   University.  Licensed under the Apache License, Version 2.0 (the "License");
 *   you may not use this file except in compliance with the License.
 * 
 * You may obtain a copy of the License at
 * 
 * http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software distributed
 *   under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 *   CONDITIONS OF ANY KIND, either express or implied. See the License for the
 *   specific language governing permissions and limitations under the License.
 * ---  END LICENSE_HEADER BLOCK  ---
*/

/* Collection List */
.collection-list {
  margin-bottom: 3rem;
}

.collection-unit-wrapper {
  border-top: 2px dotted #1f3f42;
  margin-top: 6rem;

  &:first-of-type {
    border-top: none;
    margin-top: inherit;
  }
}

.collection-list-unit-headline {
  font-size: 24px;
  font-weight: 500;
}

.collection-list-sticky-utils {
  margin-bottom: 3rem;
}

.collection-list-view-toggle-label {
  font-weight: bold;
  margin-right: 1rem;
}

.collection-details {
  dd > a {
    vertical-align: middle;
  }
}

/* Collection cards */
.collection-card {
  height: 95%;
  overflow: hidden;

  // For smaller screens set height to auto
  @media screen and (max-width: 575.98px) {
    height: auto;
  }

  .document-thumbnail {
    img {
      width: 100%;
    }
  }

  .card-body {
    padding-top: 5px;
    padding-bottom: 0px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.collection-card-description {
  padding: 0;

  .italic {
    font-style: italic;
  }

  // When `line-clamp` is used with `display: -webkit-box`
  // contains text to a given amount of lines (e.g.: 2)
  // Reference: https://mgearon.com/css/line-clamp-css-guide/
  h4 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
  }
}

.card-text {
  font-size: 14px;

  dt, dd {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }

  // Limit metadata values to 2 lines in cards
  dd {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.search-within-facets {
  margin-left: 16px;
}

/* Collection Search */
.search-wrapper {
  margin-top: 5rem;
}

.collection-search-results-wrapper {
  position: relative;
}

/* Search Results card */
.search-within-search-results {
  margin-top: 3rem;
  padding-left: 0;

  // Using flexbox to adjust all card heights to match the tallest card height 
  @media screen and (min-width: 575.98px) {
    display: flex;
    flex-direction: row;
    padding: 10px;
  
    li {
      padding-right: 10px;
      flex-grow: 1;
      width: 33%;
  
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .document-thumbnail {
    position: relative;
  }
}

@media (max-width: 767px) {
  .page-header {
    padding-left: 15px;
  }

  .description {
    padding: 1.5rem 3rem;
  }

  .filter-btn {
    float: left;
    margin-top: 10px;
  }
}

.badge-dark {
  background-color: #1e1e1e;
}

.timestamp {
  position: absolute;
  bottom: 5px;
  right: 20px;
}

.field-name {
  color: gray;
}

.sort-pagination {
  margin-left: 10px;
  margin-top: 7px;
}

.mr-2 {
  margin-right: 2px;
}

.facet-badges {
  margin-left: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
}

@media (max-width: 24em) {
  .search-bar {
    width: 100%;
  }
}

@media (max-width: 32em) {
  .search-bar {
    width: 100%;
  }
}

@media (min-width: 48em) {
  .search-bar {
    width: 50%;
  }
}

.inline {
  display: inline;
  margin-left: 8px;
}

.no-search-results {
  margin-left: 8px;
  margin-top: 2rem;
  font-size: 16px;
}

.upper {
  text-transform: uppercase;
}

.sort-btn {
  background-color: white;
  color: #2a5459;
}

.sort-btn.active,
.sort-btn.active:hover,
.sort-btn:hover {
  color: white !important;
  background-color: #2a5459 !important;
}

.search-input {
  width: 100%;
}

.more-content span {
  display: none;
}

.read-more {
  display: block;
  width: fit-content;
  margin-top: 10px;
}
