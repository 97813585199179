/* 
 * Copyright 2011-2024, The Trustees of Indiana University and Northwestern
 *   University.  Licensed under the Apache License, Version 2.0 (the "License");
 *   you may not use this file except in compliance with the License.
 * 
 * You may obtain a copy of the License at
 * 
 * http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software distributed
 *   under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 *   CONDITIONS OF ANY KIND, either express or implied. See the License for the
 *   specific language governing permissions and limitations under the License.
 * ---  END LICENSE_HEADER BLOCK  ---
*/

[class*="ramp--"] {
  font-family: Arial, Helvetica, sans-serif;
}

.ramp--media_player {
  .video-js .vjs-big-play-button {
    left: 55% !important;
  }
  .video-js.vjs-audio-only-mode {
    // Disable tooltips for volume and progress in embedded
    // audio. Viewport is too small to display them.
    .vjs-volume-panel:hover .vjs-mouse-display,
    .vjs-custom-progress-bar .tooltiptext {
      display: none !important;
    }
  }
  @media (max-width: 585px) {
    .video-js .vjs-big-play-button {
      scale: 1.5;
    }

    .video-js .vjs-control-bar {
      font-size: 90% !important;
    }

    // reduce player height to match with adjusted font-size
    // for smaller screens
    .video-js.vjs-audio {
      min-height: 2.9em;
    }
  }
  .video-js .vjs-title-bar .vjs-title-link {
    font-size: 125%;
  }
}
